module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/cactus.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"017ddfe48108327d1e047452d0ce0e1e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145405844-7","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Valérie Volral | Psychologue Clinitienne","short_name":"Valérie Volral Psy","description":"Psychologue Clinitienne à Colfontaine - 065/67 43 14 - Enfants, Ados, Adultes et Famille - Déprime, Trouble Anxieux, Éducation des enfants","lang":"fr","icon":"src/images/cactus.png","start_url":"/","background_color":"#CCFFE2","theme_color":"#CCFFE2","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"017ddfe48108327d1e047452d0ce0e1e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
